import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import * as BS from 'react-bootstrap'




function App() {
  return (
    <div className="App">
      
      <BS.Jumbotron fluid>
        <BS.Container>
          <BS.Image src="nelson.png" fluid roundedCircle style={{height: '50vh'}}/>
          <h1>Nelson Conover</h1>
          <p>
            My name is Nelson Conover. Welcome to my website.
          </p>
        </BS.Container>
      </BS.Jumbotron>
    </div>
  );
}

export default App;
